@tailwind base;
@tailwind components;
@tailwind utilities;

@layer components {
  .card {
    @apply sm:rounded-md shadow-md px-4 py-2;
  }
  .smig-border {
    @apply border border-gray-300 border-solid bg-secondary rounded-sm;
  }
}

@layer base {
  html {
    -webkit-tap-highlight-color: transparent;
  }
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
